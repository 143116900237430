import { HttpParams } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { Corretor } from './app/shared/models/corretor';

export const PAGE_TITLE: string = 'Private Imóveis';
export const KEY_SESSION: string = 'pi_session';
export const UTM_SESSION: string = 'pi_utm';
export const COOKIE_REGION: string = 'pi-region';
export const COOKIE_LANDINGPAGE: string = 'pi-lp';
export const TOKEN_SESSION: string = 'pvt_web_tkn';
export const REFRESHTOKEN_SESSION: string = 'pvt_web_rtkn';

export const UFS = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const toStr = (value: any): string => {
  return value ? value.toString() : '';
};

export const appendArray = (params: HttpParams, key: string, array: any[]): HttpParams => {
  if (array && array.length > 0) {
    array.forEach(item => {
      params = params.append(key, item);
    });
  }
  return params;
};

export const checkPhoneSintax = function (control: UntypedFormControl) {
  if (!control.value) return;
  // @diegodsp: valida telefone com sintaxe brasileira, para verificar se não foi autocompletado de forma errada
  // ex.: +55 51991-128293
  if (/^\(55\)\s[0-5]{1}\d{4}\-\d{4}/g.test(control?.value)) return { phoneSintaxError: true };
  return null;
};

export const thisCityIsFromSP = function (cidadeSlug: string): boolean {
  // @diegodsp: cidades de SP captadas em 18/08/2022
  let cidadesSP = [
    'sao-paulo',
    'sao-sebastiao',
    'carapicuiba',
    'sumare',
    'joanopolis',
    'porto-feliz',
    'braganca-paulista',
    'santana-de-parnaiba',
    'cotia',
    'barueri',
    'jundiai',
  ];
  return cidadeSlug == 'sao-paulo' || cidadesSP.includes(cidadeSlug);
};

// formata o valor em moeda
export const transformCurrency = function (value: any) {
  if (value) return stripSpecialChars(value.toString()).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
  else return value;
};

// remove caracteres especiais
export const stripSpecialChars = function (s) {
  if (!s) return s;
  else s = s.toString();
  s = s.replace(/,/g, '');
  s = s.replace(/\\u00a6/g, '');
  s = s.replace(/|/g, '');
  s = s.replace(/\./g, '');
  s = s.replace(/;/g, '');
  s = s.replace(/\//g, '');
  s = s.replace(/\\/g, '');
  s = s.replace(/|/g, '');
  s = s.replace(/</g, '');
  s = s.replace(/>/g, '');
  s = s.replace(/:/g, '');
  s = s.replace(/\?/g, '');
  s = s.replace(/\"/g, '');
  s = s.replace(/'/g, '');
  s = s.replace(/!/g, '');
  s = s.replace(/@/g, '');
  s = s.replace(/#/g, '');
  s = s.replace(/$/g, '');
  s = s.replace(/%/g, '');
  s = s.replace(/\\u00a8/g, '');
  s = s.replace(/&/g, '');
  s = s.replace(/\*/g, '');
  s = s.replace(/\(/g, '');
  s = s.replace(/\)/g, '');
  s = s.replace(/-/g, '');
  s = s.replace(/_/g, '');
  s = s.replace(/\+/g, '');
  s = s.replace(/=/g, '');
  s = s.replace(/\\u00a7/g, '');
  s = s.replace(/\\u00aa/g, '');
  s = s.replace(/\\u00ba/g, '');
  s = s.replace(/\\u00b4/g, '');
  s = s.replace(/\`/g, '');
  s = s.replace(/\[/g, '');
  s = s.replace(/\{/g, '');
  s = s.replace(/\]/g, '');
  s = s.replace(/\}/g, '');
  s = s.replace(/^/g, '');
  s = s.replace(/~/g, '');
  s = s.replace(/\\u00b9/g, '');
  s = s.replace(/\\u00b2/g, '');
  s = s.replace(/\\u00b3/g, '');
  s = s.replace(/\\u00a3/g, '');
  s = s.replace(/\\u00a2/g, '');
  s = s.replace(/\\u00ac/g, '');
  return (s = s.replace(/\./g, ''));
};

// X + Y
export const iScrollTo = function (x, y) {
  internalScrollTo(x, y, '');
};

// (id do elemento)
export const iScrollToElement = function (selector = '') {
  internalScrollTo(0, 0, selector);
};

function internalScrollTo(x, y, selector) {
  try {
    if (selector) y = document.querySelector(selector).offsetTop || 0;
    window.scroll({ top: y, left: x, behavior: 'smooth' });
  } catch (error) {
    // ignora
  }
}

export const formatPhone = function (b: Corretor) {
  let tel = b.celular ? b.celular : b.fone;
  return tel ? tel.replace(/\.|-|\s|\(|\)/g, '') : '';
};

// // formata o valor em moeda
// export const transformCurrency = function (value: string): string {
//     // return stripSpecialChars(value)?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
//     let v = +stripSpecialChars(value);
//     return Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0, useGrouping: true }).format(v);
// }

// // remove caracteres especiais
// export const stripSpecialChars = function (a: string) {
//     if (!a) return;
//     return a
//         ?.replace(/,/g, "")?.replace(/\\u00a6/g, "")?.replace(/|/g, "")
//         ?.replace(/\./g, "")?.replace(/;/g, "")?.replace(/\//g, "")
//         ?.replace(/\\/g, "")?.replace(/|/g, "")?.replace(/</g, "")
//         ?.replace(/>/g, "")?.replace(/:/g, "")?.replace(/\?/g, "")
//         ?.replace(/\"/g, "")?.replace(/'/g, "")?.replace(/!/g, "")
//         ?.replace(/@/g, "")?.replace(/#/g, "")?.replace(/$/g, "")
//         ?.replace(/%/g, "")?.replace(/\\u00a8/g, "")?.replace(/&/g, "")
//         ?.replace(/\*/g, "")?.replace(/\(/g, "")?.replace(/\)/g, "")
//         ?.replace(/-/g, "")?.replace(/_/g, "")?.replace(/\+/g, "")
//         ?.replace(/=/g, "")?.replace(/\\u00a7/g, "")?.replace(/\\u00aa/g, "")
//         ?.replace(/\\u00ba/g, "")?.replace(/\\u00b4/g, "")?.replace(/\`/g, "")
//         ?.replace(/\[/g, "")?.replace(/\{/g, "")?.replace(/\]/g, "")
//         ?.replace(/\}/g, "")?.replace(/^/g, "")?.replace(/~/g, "")
//         ?.replace(/\\u00b9/g, "")?.replace(/\\u00b2/g, "")?.replace(/\\u00b3/g, "")
//         ?.replace(/\\u00a3/g, "")?.replace(/\\u00a2/g, "")?.replace(/\\u00ac/g, "")
//         ?.replace(/\./g, "");
// }

export const paramToUrl = function (value: string) {
  if (!value) return;
  try {
    // Remove todos os caracteres com acentos ou 'ç', e substitui os espaços por '-'
    return value
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.replace(/ /g, '-')
      ?.toLowerCase();
  } catch (error) {
    return value;
  }
};
