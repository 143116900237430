export class Utm {
  public utmId?: string;
  public utmName?: string;
  public utmSource?: string;
  public utmMedium?: string;
  public utmCampaign?: string;
  public utmTerm?: string;
  public utmContent?: string;

  public get isEmpty(): boolean {
    return !this.utmSource || this.utmSource === null;
  }

  load() {
    if (!window?.location?.search) {
      console.log('Sem UTMs');
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    this.utmId = urlParams.get('utm_id');
    this.utmName = urlParams.get('utm_name');
    this.utmSource = urlParams.get('utm_source');
    this.utmMedium = urlParams.get('utm_medium');
    this.utmCampaign = urlParams.get('utm_campaign');
    this.utmTerm = urlParams.get('utm_term');
    this.utmContent = urlParams.get('utm_content');
    console.log('UTMs', JSON.stringify(this));
  }
}
