import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Corretor } from 'src/app/shared/models/corretor';
import { ContatoService } from 'src/app/shared/services/contato.service';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { checkPhoneSintax, formatPhone } from 'src/globals';

declare let $: any; // jquery

@Component({
  selector: 'app-corretor-whatsapp',
  templateUrl: './corretor-whatsapp.component.html',
  styleUrls: ['./corretor-whatsapp.component.scss'],
})
export class CorretorWhatsappComponent implements AfterContentInit {
  @Input() broker?: Corretor;
  @Input() propertyCode?: string;
  @Input() iconOnly: boolean = false;
  fmGroup: UntypedFormGroup;
  sending = false;

  constructor(
    private ssr: SsrUtilService,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private ga: AnalyticsService,
    private contatoService: ContatoService
  ) {
    this.fmGroup = this.formBuilder.group({
      name: [null, Validators.required],
      phone: [
        null,
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15),
        Validators.compose([checkPhoneSintax]),
      ],
      email: [null, Validators.required, Validators.email],
    });
  }

  ngAfterContentInit(): void {
    // manda verificar atualizações
    this.cd.detectChanges();
  }

  onSend() {
    // Valida os campos do form
    if (this.fmGroup.invalid) return;
    this.sending = true;

    // envia
    const { name, phone, email } = this.fmGroup.value;
    // TODO: property code
    this.contatoService.enviaWhatsappCorretor(this.broker?.slug, this.propertyCode, name, email, phone).subscribe(
      result => {
        this.fmGroup.reset(); // limpa os campos do form
        this.ga.trackEvent('click', 'whatsapp', result?.celular ?? this.broker?.celular);
        this.sending = false;

        let infoText = `Olá, gostaria de saber mais informações. Obrigado!`;
        if (this.propertyCode)
          infoText = `Olá, gostaria de saber mais informações do imóvel código ${this.propertyCode}. Obrigado!`;
        const whatsappInfoText = encodeURI(infoText);

        let brokerPhone = formatPhone(result);
        if (!brokerPhone || brokerPhone.length <= 0) brokerPhone = formatPhone(this.broker);

        window.open(`https://api.whatsapp.com/send?phone=55${brokerPhone}&text=${whatsappInfoText}`);
        if (this.ssr?.isBrowser) $('#modal_whatsapp_corretor').modal('hide');
      },
      error => {
        this.sending = false;
        SessionManager.handleHttpError(error, 'Falha ao enviar sua solicitação');
      }
    );
  }
}
