<a
  href="#"
  class="mt-2 i-btn-whatsapp"
  target="_blank"
  rel="noopener"
  title="Whatsapp do corretor"
  data-toggle="modal"
  data-target="#modal_whatsapp_corretor">
  <i *ngIf="iconOnly" class="iconify" data-icon="mdi-whatsapp"></i>
  <div *ngIf="!iconOnly" class="badge badge-whatsapp p-3">
    CONTATE O ESPECIALISTA <i class="iconify" data-icon="mdi-whatsapp" style="font-size: 150%"></i>
  </div>
</a>
<!-- modal encomende seu imóvel -->
<div
  class="modal fade"
  id="modal_whatsapp_corretor"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  data-backdrop="static">
  <div class="modal-dialog" role="document">
    <form (ngSubmit)="onSend()" [formGroup]="fmGroup">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="i-title text-center mt-2 w-100">CONTATE O ESPECIALISTA</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <picture>
              <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
              <img src="assets/img/btn_fechar_b.png" style="width: 17px; height: 16px" alt="Fechar" />
            </picture>
          </button>
        </div>

        <div class="modal-body row px-1 px-lg-4">
          <div class="d-flex flex-column flex-wrap i-text text-left px-4 py-3">
            <div class="mb-4" style="font-size: 110%">
              Olá! Preencha os campos abaixo para iniciar a conversa no WhatsApp com nosso especialista.
            </div>
            <div class="form-row px-2 px-lg-5">
              <mat-form-field class="col-12 mx-auto mb-3 i-text" appearance="fill">
                <mat-label class="i-text">SEU NOME</mat-label>
                <input
                  matInput
                  id="form-whatsapp-name"
                  type="text"
                  class="i-text pb-1"
                  maxlength="255"
                  formControlName="name" />
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('name').errors?.required">
                  CAMPO OBRIGATÓRIO
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 mx-auto mb-3 mt-1" appearance="fill">
                <mat-label class="i-text">SEU TELEFONE</mat-label>
                <input
                  matInput
                  id="form-whatsapp-phone"
                  type="text"
                  class="i-text pb-2"
                  placeholder="(00) 0000-00000"
                  mask="(00) 0000-0000||(00) 00000-0000"
                  minlength="14"
                  maxlength="15"
                  formControlName="phone" />
                <mat-error
                  class="i-text-sm mt-1"
                  *ngIf="fmGroup.get('phone').errors?.minlength && !fmGroup.get('phone').errors?.required">
                  POR FAVOR, INFORME NO MÍNIMO 14 DÍGITOS
                </mat-error>
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('phone').errors?.phoneSintaxError">
                  POR FAVOR, VERIFIQUE O NÚMERO DIGITADO
                </mat-error>
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('phone').errors?.required">
                  CAMPO OBRIGATÓRIO
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 mx-auto mb-3 mt-1" appearance="fill">
                <mat-label class="i-text">SEU E-MAIL</mat-label>
                <input
                  matInput
                  id="form-whatsapp-email"
                  type="email"
                  class="i-text pb-2"
                  maxlength="255"
                  formControlName="email" />
                <mat-error
                  class="i-text-sm mt-1"
                  *ngIf="fmGroup.get('email').errors?.email && !fmGroup.get('email').errors?.required">
                  POR FAVOR, INFORME UM E-MAIL VÁLIDO
                </mat-error>
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('email').errors?.required">
                  CAMPO OBRIGATÓRIO
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center bg-white">
          <!-- data-dismiss="modal" aria-label="Close" -->
          <button id="form-whatsapp-submit" type="submit" class="i-btn" [disabled]="sending">
            {{ !sending ? 'INICIAR CONVERSA' : 'INICIANDO...' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
