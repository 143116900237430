import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { SessionManager } from './session-manager';

@Injectable()
export class RequestOptionsService implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private refreshTokenSubject$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private sm: SessionManager
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // bypass
    if (req.url.startsWith('http') || req.url.includes('analytics')) return next.handle(req);

    // headers básicos
    let headers = req.headers.set('Content-Type', 'application/json').set('os', 'web');

    // clone inicial
    req = req.clone({
      url: `${environment.baseUrl}/${req.url}`,
      headers,
    });

    // 2º bypass
    if (req.url.includes('atualizar-token')) return next.handle(req);
    else if (req.url.includes('logout')) return next.handle(this.injectToken(req));

    // handle token
    if (this.sm?.hasToken() && this.sm?.isTokenExpired()) return this.refreshToken(req, next);

    return next.handle(this.injectToken(req)).pipe(
      catchError(err => {
        if (err.status == 403) return this.refreshToken(req, next);
        else return throwError(err);
      })
    );
  }

  injectToken(req: HttpRequest<any>) {
    const token = this.sm?.getToken();
    if (!token) return req;
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject$.next(false);
      return this.authService.refreshToken().pipe(
        switchMap(res => {
          this.sm.login(res.token, res.refreshToken);
          this.isRefreshing = false;
          this.refreshTokenSubject$.next(true);
          return next.handle(this.injectToken(req));
        }),
        catchError(err => {
          if (err.status == 401) {
            // @diegodsp: somente para a Private, se 401, limpa token e segue o baile
            this.sm.logout();
            return next.handle(req);
          }
          this.isRefreshing = false;
          console.error('Refreshing token', err);

          // // redireciona o login
          // this.router.navigate(['/auth/logout'], { queryParams: { returnUrl: this.router.url } });

          return of(undefined);
        })
      );
    } else {
      return this.refreshTokenSubject$.pipe(
        filter(res => res !== false),
        take(1),
        switchMap(_res => next.handle(this.injectToken(req)))
      );
    }
  }
}
