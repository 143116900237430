import { JwtHelperService } from '@auth0/angular-jwt';
import { Search } from './search';
import { Utm } from './utm';

export class SessionDataAuth {
  id: string;
  name: string;
  email: string;
  modules: string;

  constructor(values?: any) {
    if (values) Object.assign(this, values);
  }
}

export class SessionData {
  sid: string;
  tkn: string;
  favorites: string[]; // códigos de imóvel
  discards: string[]; // códigos de imóvel
  viewType: number;
  _search: Search;
  clientTkn: string;
  brokerSlug?: string;

  constructor() {
    this.favorites = [];
    this.discards = [];
    this._search = new Search();
  }

  public get clientFirstName(): string {
    if (this.clientTkn) {
      const jwt = new JwtHelperService();
      const token = jwt.decodeToken(this.clientTkn);
      if (token && token.unique_name) {
        const [firstName, _] = token.unique_name.match(/\w+(?=\s)|\w+/g);
        return firstName;
      }
    }
    return null;
  }

  public get search(): Search {
    if (this._search instanceof Search) return this._search;
    else return new Search(this._search);
  }

  public set search(v: Search) {
    this._search = v;
  }
}
